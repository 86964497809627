import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
    selector: 'app-message-whatsapp-dialog',
    templateUrl: './message-whatsapp-dialog.component.html',
    styleUrls: ['./message-whatsapp-dialog.component.scss']
})
export class MessageWhatsappDialogComponent implements OnInit {

    person = {};

    basicFormGroup: FormGroup;

    constructor(
        private dialogRef: MatDialogRef<MessageWhatsappDialogComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data
    ) { }

    ngOnInit() {
        this.initBasicForm();
        this.get();
    }


    initBasicForm() {
        this.basicFormGroup = this._formBuilder.group({
            mobile_ext: ['', Validators.required],
            mobile_phone: ['', Validators.required],
            message: ['', Validators.required],
        });
    }

    get() {
        if (this.data.person) {
            this.person = this.data.person;
            this.person['mobile_ext'] = this.person['mobile_ext'] ? this.person['mobile_ext'] : '+34';
            this.person['mobile_phone'] = this.person['mobile_phone'] ? this.person['mobile_phone'] : this.person['phone'];
        } else {
            this.person['mobile_ext'] = '+34';
            this.person['mobile_phone'] = '';
        }
        this.person['message'] = '';

        this.setForm(this.person);
    }


    setForm(person) {
        this.basicFormGroup.setControl('mobile_ext', new FormControl(person.mobile_ext));
        this.basicFormGroup.setControl('mobile_phone', new FormControl(person.mobile_phone));
        this.basicFormGroup.setControl('message', new FormControl(person.message));
    }

    getPhone (ext, mobile) {
        if (mobile) {
            const number = `${ext}${mobile}`;

            return number;
        }

        return null;
    }

    whatsapp() {
        this.person = this.basicFormGroup.value;
        const number = this.getPhone(this.person['mobile_ext'].split(' ').join(''), this.person['mobile_phone'].split(' ').join(''));
        const text = this.person['message'];

        const url = `whatsapp://send?text=${text}&phone=${number}&abid=${number}`;

        window.open(url, '_blank');
    }

    send() {
        this.whatsapp();
        this.dialogRef.close('save');
    }

    closed() {
        this.dialogRef.close('cancel');
    }
}
