import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

// Services:
import { LanguageFourmiService } from '../../shared/language-fourmi.service';
import { LayoutService } from '../../shared/layout.service';
import { UserPoliticiesService } from '../../shared/user-politicies.service';


@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    userPoliticies;
    style = { /*height: '650px !important'*/ };

    @Input() opened?;
    @Output() changed = new EventEmitter<boolean>();

    constructor(
        private languageFourmi: LanguageFourmiService,
        private layoutService: LayoutService,
        private userPoliticiesService: UserPoliticiesService,
    ) { }

    ngOnInit() {
        this.languageFourmi.set();
        this.userPoliticiesInit();
        this.initLayout();
    }

    initLayout() {
        this.style = { height: this.layoutService.home() + 'px !important' };
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();
    }

    onPress() {
        if (this.layoutService.mobile()) {
            this.changed.emit(this.opened);
        }
    }
}
