import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";

import { ApiFourmiService } from "../../shared/api-fourmi.service";

import { TranslateService } from "@ngx-translate/core";

const API = environment.apiUrl;

@Component({
    selector: "app-select-country-id-invoice",
    templateUrl: "./select-country-id-invoice.component.html",
    styleUrls: ["./select-country-id-invoice.component.scss"],
})
export class SelectCountryIdInvoiceComponent implements OnInit {
    @Input() countryId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    API;
    countries = [];

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
        private translate: TranslateService
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.getCountries();
    }

    getCountries() {
        //console.log(this.countryId);

        this.autHttp
            .get(`${this.API}/translate/crm-type.country.name`)
            .map((data) => data.json()["crm-type.country.name"])
            .subscribe((response) => {
                this.countries = Object.keys(response).map(function (index) {
                    const obj = {};
                    obj["name"] = response[index];
                    obj["value"] = +index;
                    return obj;
                });
            });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
