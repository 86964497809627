import { NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';

import { CoreModule } from './core/core.module';
import { CustomTranslateLoader } from './shared/custom-translate-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import * as $ from 'jquery';
import { FullCalendarModule } from 'ng-fullcalendar';



import { Router } from '@angular/router';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        FullCalendarModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useClass: CustomTranslateLoader,
              deps: [HttpClient]
            }
          }),
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private router: Router) {
        this.diagnosticRouter();
    }

    // Diagnostic only: inspect router configuration
    diagnosticRouter() {
        //console.log('Routes: ', JSON.stringify(this.router.config, undefined, 2));
    }
}
