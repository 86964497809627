import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Http, RequestOptions } from '@angular/http';
import { AuthHttp, AuthConfig, AuthModule } from 'angular2-jwt';

import { AuthGuardService } from './shared/auth-guard.service';
import { AuthService } from './shared/auth.service';
import { LanguageFourmiService } from '../shared/language-fourmi.service';
import { UserPoliticiesService } from '../shared/user-politicies.service';

import { CoreRoutingModule } from './core.routing';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MenuComponent } from './menu/menu.component';
import { SharedModule } from '../shared/shared.module';


export function authHttpServiceFactory(http: Http, options: RequestOptions) {
    return new AuthHttp(new AuthConfig({
        tokenName: 'token-flooges-panel',
        tokenGetter: (() => localStorage.getItem('token-flooges-panel'))
    }), http, options);
}

@NgModule({
    imports: [
        AuthModule,
        SharedModule,
        CoreRoutingModule
    ],
    exports: [
        HomeComponent,
    ],
    declarations: [
        HeaderComponent,
        HomeComponent,
        LoginComponent,
        LogoutComponent,
        MenuComponent
    ],
    providers: [
        AuthService,
        AuthGuardService,
        { provide: AuthHttp, useFactory: authHttpServiceFactory, deps: [Http, RequestOptions] },
        LanguageFourmiService,
        UserPoliticiesService
    ]
})
export class CoreModule {
    // Evita que el Modulo se importe mas de una vez ( es un modulo singletons ):
    constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(`'CoreModule' ya se ha cargado. Importa este modulo solo en la aplicacion principal 'AppModule'.`);
        }
    }
}
