import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/*
    SERVICIO: se encarga de seterar la url de la API dependiendo del idioma del usuario.
*/

@Injectable()
export class ApiFourmiService {
    constructor() {}

    get() {
        const lang = JSON.parse(localStorage.getItem('user'))['language'];
        const API = environment.apiUrlShort + lang + '/api';

        return API;
    }
}
