import { Component, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Component({
    selector: 'app-select-subfranchise',
    templateUrl: './select-subfranchise.component.html',
    styleUrls: ['./select-subfranchise.component.scss']
})
export class SelectSubfranchiseComponent implements OnChanges {

    API;

    @Input() disable;
    @Input() icon?;
    @Input() franchiseId;
    @Input() subfranchiseId?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    subfranchises;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getSubfranchises();
    }

    getSubfranchises() {
        const params = [];

        params['franchise_id'] = this.franchiseId;

        this.autHttp.get(`${this.API}/franchise-sub`, {params})
            .map(data => data.json().data)
            .subscribe(response => this.subfranchises = response);
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
