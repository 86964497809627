import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Component({
    selector: 'app-select-color',
    templateUrl: './select-color.component.html',
    styleUrls: ['./select-color.component.scss']
})
export class SelectColorComponent implements OnInit {

    API;
    colors = [
        { id: 'red', name: 'kw-label-red'  },
        { id: 'pink', name: 'kw-label-pink' },
        { id: 'purple', name: 'kw-label-purple' },
        //{ id: 'deep-purple', name: 'kw-label-deep-purple' },
        { id: 'indigo', name: 'kw-label-indigo' },
        { id: 'blue', name: 'kw-label-blue' },
       // { id: 'light-blue', name: 'kw-label-light-blue' },
        { id: 'cyan', name: 'kw-label-cyan'  },
        { id: 'teal', name: 'kw-label-teal'  },
        { id: 'green', name: 'kw-label-green'  },
        //{ id: 'light-green', name: 'kw-label-light-green' },
        { id: 'lime', name: 'kw-label-lime' },
        { id: 'yellow', name: 'kw-label-yellow'  },
        //{ id: 'amber', name: 'kw-label-amber' },
        { id: 'orange', name: 'kw-label-orange'  },
        //{ id: 'deep-orange', name: 'kw-label-deep-orange'  },
        { id: 'brown', name: 'kw-label-brown'  },
        { id: 'grey', name: 'kw-label-grey' },
        //{ id: 'blue-grey', name: 'kw-label-blue-grey' },
        //{ id: 'grey-dark', name: 'kw-label-grey-dark' },
        { id: 'black', name: 'kw-label-black' },
    ];

    @Input() disable?;
    @Input() icon?;
    @Input() colorId;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    constructor(private autHttp: AuthHttp,
                private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
