import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

const API = environment.apiUrl;

@Component({
    selector: 'app-select-agency-user-type',
    templateUrl: './select-agency-user-type.component.html',
    styleUrls: ['./select-agency-user-type.component.scss']
})
export class SelectAgencyUserTypeComponent implements OnInit {

    @Input() typeId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    types = [];

    constructor(private autHttp: AuthHttp,
                private translate: TranslateService) { }

    ngOnInit() {
        this.getTypes();
    }

    getTypes() {
        let types = [];

        this.translate.get('crm-type.user', {value: 'type'}).subscribe((response: string) => {
            types = response['type'];
        });

        this.types = Object.keys(types).map(function(index) {
            const obj = {};
            obj['name'] = types[index];
            obj['value'] = +index;
            return obj;
        });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
