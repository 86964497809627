import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";

import { ApiFourmiService } from "../../shared/api-fourmi.service";

@Component({
    selector: "app-select-agency",
    templateUrl: "./select-agency.component.html",
    styleUrls: ["./select-agency.component.scss"],
})
export class SelectAgencyComponent implements OnInit {
    API;

    @Input() agencyId;
    @Input() franchiseId;
    @Input() icon?;
    @Input() required?;
    @Input() text;
    @Output() changed = new EventEmitter<boolean>();

    agencies = [];

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.getAgencies();
    }

    getAgencies() {
        const params = {
            franchise_id: this.franchiseId,
            status_id: 1,
        };

        this.autHttp
            .get(`${this.API}/agency`, { params })
            .map((data) => data.json())
            .subscribe((response) => {
                this.agencies = Object.keys(response.data).map(function (
                    index
                ) {
                    const obj = {};
                    obj["name"] = response.data[index].name;
                    obj["value"] = response.data[index].id;
                    return obj;
                });
            });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
