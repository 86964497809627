import { Injectable } from '@angular/core';

@Injectable()
export class LayoutService {

    // FOOTER
    footer: number = 0;
    toolbar: number = 64;


    height() {
        return window.innerHeight;
    }

    width() {
        return window.innerWidth;
    }

    mobile() {
        if (window.innerWidth < 600) {
            return true;
        }

        return false;
    }

    check() {
        if (window.innerWidth < 600) {
            this.footer = 0;
            this.toolbar = 64;
        }
    }

    home() {
        this.check();

        return window.innerHeight - this.toolbar;
    }

    constructor() {}
}
