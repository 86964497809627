import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { LoginComponent } from "./core/login/login.component";
import { AuthGuardService } from "./core/shared/auth-guard.service";
import { PoliciesGuardService } from "./core/shared/policies-guard.service";
import { HomeComponent } from "./core/home/home.component";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: "agency",
                loadChildren: "app/agency/agency.module#AgencyModule",
            },
            {
                path: "meeting",
                loadChildren: "app/meeting/meeting.module#MeetingModule",
            },
            {
                path: "dashboard",
                loadChildren: "app/dashboard/dashboard.module#DashboardModule",
            },
            {
                path: "franchise",
                loadChildren: "app/franchise/franchise.module#FranchiseModule",
            },
            {
                path: "interest",
                loadChildren: "app/interest/interest.module#InterestModule",
            },
            {
                path: "origin",
                loadChildren: "app/origin/origin.module#OriginModule",
            },
            {
                path: "precontact",
                loadChildren:
                    "app/precontact/precontact.module#PrecontactModule",
            },
            {
                path: "contact",
                loadChildren: "app/contact/contact.module#ContactModule",
            },
            {
                path: "module",
                loadChildren: "app/module/module.module#ModuleModule",
            },
            {
                path: "service-external",
                loadChildren:
                    "app/service-external/service-external.module#ServiceExternalModule",
            },
            {
                path: "state",
                loadChildren: "app/state/state.module#StateModule",
            },
            {
                path: "subfranchise",
                loadChildren:
                    "app/subfranchise/subfranchise.module#SubfranchiseModule",
            },
            {
                path: "team",
                loadChildren: "app/team/team.module#TeamModule",
            },
            {
                path: "user",
                loadChildren: "app/user/user.module#UserModule",
            },
        ],
    },
    {
        path: "",
        children: [{ path: "login", component: LoginComponent }],
    },

    { path: "", redirectTo: "/dashboard", pathMatch: "full" },
    { path: "**", redirectTo: "/dashboard" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
