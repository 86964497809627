import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-select-activity',
    templateUrl: './select-activity.component.html',
    styleUrls: ['./select-activity.component.scss']
})
export class SelectActivityComponent implements OnInit {

    @Input() activityId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    activities = [];

    constructor(private autHttp: AuthHttp,
                private translate: TranslateService
                ) { }

    ngOnInit() {
        this.getActivities();
    }

    getActivities() {
        let activities = [];

        this.translate.get('crm-type.business', {value: 'activity'}).subscribe((response) => {
            activities = response['activity'];

            this.activities = Object.keys(activities).map(function(index) {
                const obj = {};
                obj['name'] = activities[index];
                obj['value'] = parseInt(index, 10);
                return obj;
            });
        });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
