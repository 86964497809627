import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';
import { UserPoliticiesService } from '../../shared/user-politicies.service';

@Component({
    selector: 'app-select-origin',
    templateUrl: './select-origin.component.html',
    styleUrls: ['./select-origin.component.scss']
})
export class SelectOriginComponent implements OnInit {

    API;
    origins;
    userPoliticies;

    @Input() disable?;
    @Input() icon?;
    @Input() originId;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    constructor(private autHttp: AuthHttp,
                private apiFourmi: ApiFourmiService,
                private userPoliticiesService: UserPoliticiesService,
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.userPoliticiesInit();

        this.getStates();
    }

    getStates() {
        const params = [];

        if (this.userPoliticies.type_id < 50) {
            params['franchise_id'] = this.userPoliticies.franchise_id;
        }

        this.autHttp.get(`${this.API}/contact-origin`, {params})
            .map(data => data.json().data)
            .subscribe(response => this.origins = response);
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
