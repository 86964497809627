import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-select-locale',
    templateUrl: './select-locale.component.html',
    styleUrls: ['./select-locale.component.scss']
})
export class SelectLocaleComponent implements OnInit {

    @Input() localeId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    locales = [];

    constructor(private autHttp: AuthHttp,
                private translate: TranslateService
                ) { }

    ngOnInit() {
        this.getLocale();
    }

    getLocale() {
        let locales = [];

        this.translate.get('crm-type.agency', {value: 'locale'}).subscribe((response) => {
            locales = response['locale'];

            this.locales = Object.keys(locales).map(function(index) {
                const obj = {};
                obj['name'] = locales[index];
                obj['value'] = index;

                return obj;
            });
        });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
