import { Component, OnInit } from '@angular/core';

import { LanguageFourmiService } from '../../shared/language-fourmi.service';
import { LayoutService } from '../../shared/layout.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    style = {  };

constructor(
    private languageFourmi: LanguageFourmiService,
    private layoutService: LayoutService
    ) { }

    opened: boolean;

    ngOnInit() {
        this.initLayout();
        this.languageFourmi.set();

        if (!this.layoutService.mobile()) {
            this.opened = true;
        }
    }

    onCloseSidenav(event) {
        this.opened = !event;
    }

    onChangedOpen(event) {
        this.opened = !event;
    }

    initLayout() {
       this.style = { height: this.layoutService.home() + 'px' };
    }
}
