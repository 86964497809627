import { Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/Observable';

/*
    SERVICIO: se encarga cargar los archivos de traducción desde una API remota según idioma que se le proporcione.
*/

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
    contentHeader = new Headers({'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'});

    constructor(private http: Http) {}
    getTranslation(lang: string): Observable<any> {
        const apiAddress = environment.apiUrlShort + lang + '/api/translate/fourmi';

        const apiAddressEN = environment.apiUrlShort + 'en/api/translate/fourmi';

        return Observable.create(observer => {
          this.http.get(apiAddress, { headers: this.contentHeader }).subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();
                },
            error => {
                //  failed to retrieve from api, switch to local
                this.http.get(apiAddressEN).subscribe((res: Response) => {
                    observer.next(res);
                    observer.complete();
                });
            }
            );
        });
    }
}
