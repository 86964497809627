import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";

import { ApiFourmiService } from "../../shared/api-fourmi.service";

import { UserPoliticiesService } from "../../shared/user-politicies.service";

const agency_team_id = environment.agency_team_id;

@Component({
    selector: "app-select-client",
    templateUrl: "./select-client.component.html",
    styleUrls: ["./select-client.component.scss"],
})
export class SelectClientComponent implements OnInit {
    @Input() disable?;
    @Input() icon?;
    @Input() clientId;
    @Input() limit;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    clients;
    API;
    userPoliticies;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
        private route: ActivatedRoute,
        private router: Router,
        private userPoliticiesService: UserPoliticiesService
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.userPoliticiesInit();
        this.getClients();
    }

    getClients() {
        const params = [];

        if (this.userPoliticies.type_id < 50) {
            params["franchise_id"] = this.userPoliticies.franchise_id;
        }

        params["agency_id"] = agency_team_id;
        params["limit"] = this.limit;

        this.autHttp
            .get(`${this.API}/client`, { params })
            .map((data) => data.json().data)
            .subscribe((response) => (this.clients = response));
    }

    selected(event) {
        this.changed.emit(event.value);
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();

        if (this.userPoliticies.type_id < 30) {
            // No inferiores a Ususario.
            this.router.navigate(["/dashboard"]);
        }
    }
}
