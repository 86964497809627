import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

/*
    SERVICIO: se encarga de setear el idioma en la aplicación.
*/

@Injectable()
export class LanguageFourmiService {
    constructor(private translate: TranslateService) {}

    set() {
        const lang = JSON.parse(localStorage.getItem('user'))['language'];
        this.translate.use(lang);
    }

    setLanguage(lang) {
        this.translate.use(lang);
    }

    get() {
        return JSON.parse(localStorage.getItem('user'))['language'];
    }
}
