import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { MaterialModule } from "./material.module";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// Translate:
import { CustomTranslateLoader } from "../shared/custom-translate-loader";

// Components:
import { ContactShowHistoryComponent } from "../contact/contact-show-history/contact-show-history.component";
import { PrecontactShowHistoryComponent } from "../precontact/precontact-show-history/precontact-show-history.component";
import { SelectActivityComponent } from "./select-activity/select-activity.component";
import { SelectAdviserComponent } from "./select-adviser/select-adviser.component";
import { SelectAgencyComponent } from "./select-agency/select-agency.component";
import { SelectAgencyTypeComponent } from "./select-agency-type/select-agency-type.component";
import { SelectAgencyUserRankComponent } from "./select-agency-user-rank/select-agency-user-rank.component";
import { SelectAgencyUserTypeComponent } from "./select-agency-user-type/select-agency-user-type.component";
import { SelectClientComponent } from "./select-client/select-client.component";
import { SelectClientTypeComponent } from "./select-client-type/select-client-type.component";
import { SelectColorComponent } from "./select-color/select-color.component";
import { SelectCountryCodeInvoiceComponent } from "./select-country-code-invoice/select-country-code-invoice.component";
import { SelectCountryIdInvoiceComponent } from "./select-country-id-invoice/select-country-id-invoice.component";
import { SelectIvaTypeComponent } from "./select-iva-type/select-iva-type.component";
import { SelectFranchiseComponent } from "./select-franchise/select-franchise.component";
import { SelectCurrencyComponent } from "./select-currency/select-currency.component";
import { SelectLanguageComponent } from "./select-language/select-language.component";
import { SelectLocaleComponent } from "./select-locale/select-locale.component";
import { SelectModuleComponent } from "./select-module/select-module.component";
import { SelectOperationSaleComponent } from "./select-operation-sale/select-operation-sale.component";
import { SelectOperationStatusComponent } from "./select-operation-status/select-operation-status.component";
import { SelectOriginComponent } from "./select-origin/select-origin.component";
import { SelectPaymentMethodComponent } from "./select-payment-method/select-payment-method.component";
import { SelectStateComponent } from "./select-state/select-state.component";
import { SelectSubfranchiseComponent } from "./select-subfranchise/select-subfranchise.component";
import { SelectFranchiseSubfranchiseComponent } from "./select-franchise-subfranchise/select-franchise-subfranchise.component";
import { SelectUserComponent } from "./select-user/select-user.component";
import { SelectUserFranchiseSubfranchiseComponent } from "./select-user-franchise-subfranchise/select-user-franchise-subfranchise.component";
import { SelectUserAssignedComponent } from "./select-user-assigned/select-user-assigned.component";
import { SelectYearComponent } from "./select-year/select-year.component";
import { UploadBackgroundImageComponent } from "./upload-background-image/upload-background-image.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { UploadLogoComponent } from "./upload-logo/upload-logo.component";
import { UploadPhotoComponent } from "./upload-photo/upload-photo.component";

// Dialogs:
import { MessageWhatsappDialogComponent } from "./dialog/message-whatsapp-dialog/message-whatsapp-dialog.component";

// Services:
import { LayoutService } from "./layout.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [
        CommonModule,
        MessageWhatsappDialogComponent,
        ContactShowHistoryComponent,
        FormsModule,
        HttpClientModule,
        HttpModule,
        MaterialModule,
        PrecontactShowHistoryComponent,
        SelectActivityComponent,
        SelectAdviserComponent,
        SelectAgencyComponent,
        SelectAgencyTypeComponent,
        SelectAgencyUserRankComponent,
        SelectAgencyUserTypeComponent,
        SelectClientComponent,
        SelectClientTypeComponent,
        SelectColorComponent,
        SelectCountryCodeInvoiceComponent,
        SelectCountryIdInvoiceComponent,
        SelectCurrencyComponent,
        SelectIvaTypeComponent,
        SelectLanguageComponent,
        SelectLocaleComponent,
        SelectModuleComponent,
        SelectOperationSaleComponent,
        SelectOperationStatusComponent,
        SelectOriginComponent,
        SelectPaymentMethodComponent,
        SelectStateComponent,
        SelectFranchiseComponent,
        SelectSubfranchiseComponent,
        SelectFranchiseSubfranchiseComponent,
        SelectUserComponent,
        SelectUserFranchiseSubfranchiseComponent,
        SelectUserAssignedComponent,
        SelectYearComponent,
        RouterModule,
        TranslateModule,
        ReactiveFormsModule,
        UploadBackgroundImageComponent,
        UploadFileComponent,
        UploadLogoComponent,
        UploadPhotoComponent,
    ],
    declarations: [
        MessageWhatsappDialogComponent,
        ContactShowHistoryComponent,
        PrecontactShowHistoryComponent,
        SelectActivityComponent,
        SelectAdviserComponent,
        SelectAgencyComponent,
        SelectAgencyTypeComponent,
        SelectAgencyUserRankComponent,
        SelectAgencyUserTypeComponent,
        SelectClientComponent,
        SelectClientTypeComponent,
        SelectColorComponent,
        SelectCountryCodeInvoiceComponent,
        SelectCountryIdInvoiceComponent,
        SelectCurrencyComponent,
        SelectIvaTypeComponent,
        SelectLanguageComponent,
        SelectLocaleComponent,
        SelectModuleComponent,
        SelectOperationSaleComponent,
        SelectOperationStatusComponent,
        SelectOriginComponent,
        SelectPaymentMethodComponent,
        SelectStateComponent,
        SelectFranchiseComponent,
        SelectSubfranchiseComponent,
        SelectFranchiseSubfranchiseComponent,
        SelectUserComponent,
        SelectUserFranchiseSubfranchiseComponent,
        SelectUserAssignedComponent,
        SelectYearComponent,
        UploadBackgroundImageComponent,
        UploadFileComponent,
        UploadLogoComponent,
        UploadPhotoComponent,
    ],
    providers: [LayoutService],
    entryComponents: [MessageWhatsappDialogComponent],
})
export class SharedModule {}

// Importe modulos requeridos por los de mas Modulos de la APP (CommonModule, FormsModule ...)
// Declarar componentes, directivas y pipes comunes a los de mas Modulos de la APP.
// Evite proporcionar servicios en este módulo
