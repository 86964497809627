import { Component, Input, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material';

import { CommentPrecontactService } from '../../precontact/shared/comment-precontact.service';

@Component({
    selector: 'app-precontact-show-history',
    templateUrl: './precontact-show-history.component.html',
    styleUrls: ['./precontact-show-history.component.scss']
})
export class PrecontactShowHistoryComponent implements OnInit {

    @Input() precontact;
    @Input() deleteOption;
    @Input() user;

    comment = {
        franchise_id: null,
        franchise_sub_id: null,
        user_id: null,
        precontact_id: null,
        comment: null,
    };

    tableColumns = ['comment', 'info', 'options'];
    dataHistories;

    constructor(
        private commentPrecontactService: CommentPrecontactService,
    ) { }

    ngOnInit() {
        this.getHistories();
    }

    getHistories() {
        this.commentPrecontactService.listPrecontact(this.precontact.id).subscribe((response) => {
            if (!response.errors) {
                this.precontact.history = response;

                this.dataHistories = new MatTableDataSource(this.precontact.history);
            }
        });
    }

    deleteHistory(element) {
        if (this.deleteOption) {
            this.commentPrecontactService.delete(element.id).subscribe((response) => {
                if (!response.errors) {
                    const data = this.dataHistories.data;
                    const index = data.findIndex(i => i.id === element.id);
                    data.splice(index, 1);
                    this.dataHistories.data = data;
                }
            });
        }
    }

    preStore(event) {
        this.comment.comment = event;

        this.store();
    }

    store () {
        this.setComment();

        this.commentPrecontactService.store(this.comment).subscribe((response) => {
            const data = this.dataHistories.data;
            data.unshift(response);
            this.dataHistories.data = data;
            this.comment.comment  = '';
        });
    }

    setComment() {
        this.comment.franchise_id = this.user.franchise_id;
        this.comment.franchise_sub_id = this.user.franchise_sub_id;
        this.comment.user_id = this.user.id;
        this.comment.precontact_id = this.precontact.id;
    }
}
