import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { environment } from '../../../environments/environment';

import { SelectFranchiseComponent } from '../select-franchise/select-franchise.component';
import { SelectSubfranchiseComponent } from '../select-subfranchise/select-subfranchise.component';

class franchiseSubfranchise {
    franchise_id: number;
    franchise_sub_id: number;
}

@Component({
    selector: 'app-select-franchise-subfranchise',
    templateUrl: './select-franchise-subfranchise.component.html',
    styleUrls: ['./select-franchise-subfranchise.component.scss'],
})
export class SelectFranchiseSubfranchiseComponent implements OnInit {

    @Input() disable?;
    @Input() franchiseId?;
    @Input() icon?;
    @Input() subfranchiseId?;
    @Input() requiredFranchise?;
    @Input() requiredSubFranchise?;
    @Output() changed = new EventEmitter<franchiseSubfranchise>();
    changes_ids = new franchiseSubfranchise();

    constructor() { }

    ngOnInit() { }

    onChangedfranchise(event) {
        this.franchiseId = event;
        this.subfranchiseId = null;
        this.onChanged();
    }

    onChangedSubfranchise(event) {
        this.subfranchiseId = event;
        this.onChanged();
    }


    onChanged() {
        this.changes_ids["franchise_id"] = this.franchiseId;
        this.changes_ids["franchise_sub_id"] = this.subfranchiseId;

        this.changed.emit(this.changes_ids);
    }
}
