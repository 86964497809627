import { Component, Input, OnInit } from '@angular/core';

import { MatTableDataSource } from '@angular/material';

import { CommentContactService } from '../../contact/shared/comment-contact.service';

@Component({
    selector: 'app-contact-show-history',
    templateUrl: './contact-show-history.component.html',
    styleUrls: ['./contact-show-history.component.scss']
})
export class ContactShowHistoryComponent implements OnInit {

    @Input() contact;
    @Input() deleteOption;
    @Input() user;

    comment = {
        franchise_id: null,
        franchise_sub_id: null,
        user_id: null,
        contact_id: null,
        comment: null,
    };

    tableColumns = ['comment', 'info', 'options'];
    dataHistories;

    constructor(
        private commentContactService: CommentContactService,
    ) { }

    ngOnInit() {
        this.setComment();
        this.getHistories();
    }

    getHistories() {
        this.commentContactService.listContact(this.contact.id).subscribe((response) => {
            if (!response.errors) {
                this.contact.history = response;

                this.dataHistories = new MatTableDataSource(this.contact.history);
            }
        });
    }

    deleteHistory(element) {
        if (this.deleteOption) {
            this.commentContactService.delete(element.id).subscribe((response) => {
                if (!response.errors) {
                    const data = this.dataHistories.data;
                    const index = data.findIndex(i => i.id === element.id);
                    data.splice(index, 1);
                    this.dataHistories.data = data;
                }
            });
        }
    }

    preStore(event) {
        this.comment.comment = event;

        this.store();
    }

    store () {
        this.setComment();

        this.commentContactService.store(this.comment).subscribe((response) => {
            const data = this.dataHistories.data;
            data.unshift(response);
            this.dataHistories.data = data;
            this.comment.comment  = '';
        });
    }

    setComment() {
        this.comment.franchise_id = this.user.franchise_id;
        this.comment.franchise_sub_id = this.user.franchise_sub_id;
        this.comment.user_id = this.user.id;
        this.comment.contact_id = this.contact.id;
    }
}
