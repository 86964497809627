import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserPoliticiesService } from '../../shared/user-politicies.service';
import { LanguageFourmiService } from '../../shared/language-fourmi.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    userPoliticies;

    @Input() opened?;
    @Output() changed = new EventEmitter<boolean>();

    constructor(
        private languageFourmi: LanguageFourmiService,
        private userPoliticiesService: UserPoliticiesService,
    ) { }

    ngOnInit() {
        this.languageFourmi.set();
        this.userPoliticiesInit();
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();
    }

    onPress() {
        this.changed.emit(this.opened);
    }
}
