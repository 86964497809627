import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';


@Component({
    selector: 'app-select-currency',
    templateUrl: './select-currency.component.html',
    styleUrls: ['./select-currency.component.scss']
})
export class SelectCurrencyComponent implements OnInit {

    API;

    @Input() coinAgency;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    currencies;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }


    ngOnInit() {
        this.getCurrencies();
    }

    getCurrencies() {
        this.autHttp.get(`${this.API}/currency`)
            .map(data => data.json().data)
            .subscribe(response => this.currencies = response);
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
