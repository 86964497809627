import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
    `
})
export class AppComponent {
    constructor(
        translate: TranslateService,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

         // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('en');


        // Custom Icons:
        matIconRegistry.addSvgIcon('agency', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/agency.svg'));
        matIconRegistry.addSvgIcon('contact', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/contact.svg'));
        matIconRegistry.addSvgIcon('dashboard', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard.svg'));
        matIconRegistry.addSvgIcon('franchise', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/franchise.svg'));
        matIconRegistry.addSvgIcon('module', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/module.svg'));
        matIconRegistry.addSvgIcon('origin', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/origin.svg'));
        matIconRegistry.addSvgIcon('panel_user', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/panel_user.svg'));
        matIconRegistry.addSvgIcon('precontact', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/precontact.svg'));
        matIconRegistry.addSvgIcon('state', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/state.svg'));
        matIconRegistry.addSvgIcon('subfranchise', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/subfranchise.svg'));
        matIconRegistry.addSvgIcon('tasks_equip', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tasks_equip.svg'));
        matIconRegistry.addSvgIcon('tasks_my', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/tasks_my.svg'));

        // Custom Icons Social:
        matIconRegistry.addSvgIcon('blog', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/blog.svg'));
        matIconRegistry.addSvgIcon('facebook', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/facebook.svg'));
        matIconRegistry.addSvgIcon('googlepus', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/googleplus.svg'));
        matIconRegistry.addSvgIcon('linkedin', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/linkedin.svg'));
        matIconRegistry.addSvgIcon('pinterest', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/pinterest.svg'));
        matIconRegistry.addSvgIcon('twitter', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/twitter.svg'));
        matIconRegistry.addSvgIcon('whatsapp_2', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/whatsapp_2.svg'));
        matIconRegistry.addSvgIcon('whatsapp', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/whatsapp.svg'));
        matIconRegistry.addSvgIcon('youtube', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/social/youtube.svg'));
    }
 }
