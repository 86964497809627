import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Injectable()
export class MeetingService {
    API;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    list(params) {
        return this.autHttp.get(`${this.API}/meeting`, { params })
            .map(data => data.json().data);
    }

    get(id: number) {
        return this.autHttp.get(`${this.API}/meeting/${id}`)
            .map(data => data.json().data);
    }

    getParams(id: number, params) {
        return this.autHttp.get(`${this.API}/meeting/${id}`, {params})
            .map(data => data.json().data);
    }

    store(meeting) {
        return this.autHttp.post(`${this.API}/meeting`, meeting)
            .map(data => data.json().data);
    }

    update(id, meeting) {
        return this.autHttp.put(`${this.API}/meeting/${id}`, meeting)
            .map(data => data.json().data);
    }

    delete(id: number) {
        return this.autHttp.delete(`${this.API}/meeting/${id}`)
            .map(data => data.json().data);
    }

    transfer(params) {
        return this.autHttp.get(`${this.API}/meeting/transfer`, { params })
            .map(data => data.json().data);
    }
}
