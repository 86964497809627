import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../shared/auth.service';
import { LayoutService } from '../../shared/layout.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

    @Input() bottom?;

    constructor(
        private authService: AuthService,
        private layoutService: LayoutService,
        private router: Router
    ) { }

    ngOnInit() {
    }

    logout() {
        this.authService.logout().subscribe(response => {
            localStorage.removeItem('token-flooges-panel');
            localStorage.removeItem('user');
            this.router.navigate(['/login']);
        });
    }
}
