import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { tokenNotExpired } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // 'Authorization': 'my-auth-token'
    })
};

@Injectable()
export class AuthService {

    url = environment.apiUrl;

    constructor(
        private http: HttpClient
    ) { }

    login(user) {
        return this.http.post(`${this.url}/login` , user);
    }

    loggedIn() {
        // ¿Conectado?
        return tokenNotExpired('token-flooges-panel');
    }

    logout() {
        const token = localStorage.getItem('token-flooges-panel');

        return this.http.get(`${this.url}/logout?token=${token}`, httpOptions);
    }
}
