import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { TranslateService } from '@ngx-translate/core';

const API = environment.apiUrl;

@Component({
    selector: 'app-select-iva-type',
    templateUrl: './select-iva-type.component.html',
    styleUrls: ['./select-iva-type.component.scss']
})
export class SelectIvaTypeComponent implements OnInit {

    @Input() ivaId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    types;

    constructor(private autHttp: AuthHttp,
                private translate: TranslateService) { }

    ngOnInit() {
        this.getTypes();
    }

    getTypes() {
        this.translate.get('crm-type.property.tax').subscribe((response: string) => {
            this.types = response;
        });
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
