import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Headers } from '@angular/http';

import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

// const myHeader = new Headers({ 'Content-Type': `multipart/form-data; boundary=${Math.random()}` });

@Component({
    selector: 'app-upload-logo',
    templateUrl: './upload-logo.component.html',
    styleUrls: ['./upload-logo.component.scss']
})
export class UploadLogoComponent implements OnInit {

    form: FormGroup;
    loading = false;
    apiUrl = `${environment.apiUrl}/`;

    @Input() model;
    @Input() data?;
    @Output() uploaded = new EventEmitter<boolean>();
    @ViewChild('fileInput') fileInput: ElementRef;

    constructor(
        private autHttp: AuthHttp,
        private fb: FormBuilder,
    ) { }

    ngOnInit() {
        this.apiUrl += 'logo';

        this.createForm();
    }

    clearFile() {
        this.form.get('logo').setValue(null);
        this.fileInput.nativeElement.value = '';
    }

    createForm() {
        this.form = this.fb.group({
            logo: [null, Validators.required],
        });
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get('logo').setValue(file);
        }
    }

    onSubmit(event) {
        const formData = this.prepareFormData();

        this.loading = true;

        this.autHttp.post(this.apiUrl, formData)
            .map(data => data.json())
            .subscribe(
                response => {
                    this.loading = false;
                    this.clearFile();

                    event.uploaded = true;
                    this.uploaded.emit(event);
                },
                error => {
                    this.loading = false;
                    this.clearFile();

                    console.dir(error._body);
                }
            );
    }

    private prepareFormData(): any {
        const input = new FormData();
            input.append('logo', this.form.get('logo').value);
            input.append('id', this.data.id);
            input.append('model', this.model);

        return input;
    }

}
