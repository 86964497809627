import { Injectable } from '@angular/core';

@Injectable()
export class UserPoliticiesService {
    user;

    /*
        SERVICIO: se encarga cargar los datos del usuarios para permitir acceso contenido en la app.
    */

    constructor() {}

    userLocalStorage() {
        this.user = localStorage.getItem('user');
        this.user = JSON.parse(this.user);
    }


    get() {
        this.userLocalStorage();

        return this.user;
    }
}
