import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../shared/auth.service';
import { LayoutService } from '../../shared/layout.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @Input() email;
    @Input() password;

    constructor(
        private authService: AuthService,
        private layoutService: LayoutService,
        private router: Router,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        this.removeToken();
    }

    login() {
        const user = { email: this.email, password: this.password };

        this.authService.login(user).subscribe(response => {
            // Idioma de la app:
            this.translate.use(response['data']['user']['language']);

            // Carga al localstorage:
            localStorage.setItem('token-flooges-panel', response['data']['token']);
            localStorage.setItem('user', JSON.stringify(response['data']['user']));

            this.router.navigate(['/dashboard']);
        });
    }

    removeToken() {
        localStorage.removeItem('token-flooges-panel');
    }
}
