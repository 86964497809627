import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material";
import { MatTableDataSource, MatTableModule, MatSort } from "@angular/material";
import { Router } from "@angular/router";

import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";
import { LayoutService } from "../../shared/layout.service";

import { PrecontactService } from "../../precontact/shared/precontact.service";
import { UserPoliticiesService } from "../../shared/user-politicies.service";
import { MessageWhatsappDialogComponent } from "../../shared/dialog/message-whatsapp-dialog/message-whatsapp-dialog.component";
import { PrecontactTransferUserDialogComponent } from "../../precontact/dialog/precontact-transfer-user-dialog/precontact-transfer-user-dialog.component";

// const myHeader = new Headers({ 'Content-Type': `multipart/form-data; boundary=${Math.random()}` });

@Component({
    selector: "app-upload-file",
    templateUrl: "./upload-file.component.html",
    styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
    form: FormGroup;
    formData;
    loading = false;
    loadingImport = false;
    apiUrl = `${environment.apiUrl}/`;

    check = {
        count: 1,
        errors: false,
        result: true,
    };

    count = 0;
    dataElements;
    errors = "";
    result = false;

    filter = {
        franchise_sub_id: null,
    };

    fileName;

    sortDirection = "asc";
    tableColumnsPrecontact = [
        "id",
        "linking_id",
        "meeting_last",
        "status_id",
        "activity_id",
        "name",
        "phone",
        "email",
        "created_at",
        "updated_at",
        "options",
    ];

    total;
    userPoliticies;
    updated; // Precontactos actualizados ID's.

    // Efectos:
    flash = false;
    sizeFile = "";
    showChecking = false;
    showCircle = false;
    showFileName = false;
    showWarningSuccess = false;

    @Input() model;
    @Output() uploaded = new EventEmitter<boolean>();
    @ViewChild("fileInput") fileInput: ElementRef;
    @ViewChild(MatSort) sort: MatSort;

    messageWhatsappDialogRef: MatDialogRef<MessageWhatsappDialogComponent>;
    precontactTransferUserDialogRef: MatDialogRef<PrecontactTransferUserDialogComponent>;

    constructor(
        private autHttp: AuthHttp,
        private dialog: MatDialog,
        private fb: FormBuilder,
        private layoutService: LayoutService,
        private precontactService: PrecontactService,
        private router: Router,
        private userPoliticiesService: UserPoliticiesService
    ) {}

    ngOnInit() {
        this.userPoliticiesInit();
        this.createForm();
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();

        if (
            this.userPoliticies.type_id < 20 &&
            this.userPoliticies.type_id !== 15
        ) {
            // No inferiores usuario salvo usuario "comercial".
            this.router.navigate(["/dashboard"]);
        }
    }

    clearFile() {
        this.form.get("file").setValue(null);
        this.fileInput.nativeElement.value = "";
    }

    createForm() {
        this.form = this.fb.group({ file: [null, Validators.required] });
    }

    checkfile() {
        this.showChecking = true;
    }

    onFileChange(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.form.get("file").setValue(file);
            this.fileName = this.form.get("file").value.name;
        }
    }

    checkFile(event) {
        this.formData = this.prepareFormData();

        this.loading = true;
        this.showFileName = false;

        this.autHttp
            .post(this.apiUrl + "import/check/file/csv", this.formData)
            .map((data) => data.json())
            .subscribe(
                (response) => {
                    this.loading = false;
                    this.showFileName = true;
                    this.showChecking = true;

                    this.clearFile();
                    this.sizeFile = response.data.size;
                    this.check.count = response.data.num_precontact;

                    event.uploaded = true;
                    this.uploaded.emit(event);
                },
                (error) => {
                    this.loading = false;
                    this.showFileName = true;
                    this.clearFile();
                }
            );
    }

    importFile(event) {
        this.flash = true;
        this.loadingImport = true;

        this.autHttp
            .post(this.apiUrl + "import/file/csv", this.formData)
            .map((data) => data.json())
            .subscribe(
                (response) => {
                    this.errors = response.errors;

                    if (!response.errors) {
                        this.dataElements = new MatTableDataSource(
                            response.data.imported
                        );
                        this.updated = response.data.updated;
                        this.result = true;
                        this.count = response.data.length;
                        this.loadingImport = false;

                        setTimeout(() => {
                            this.flash = false;
                        }, 2000);
                    }
                },
                (error) => {
                    this.loadingImport = false;
                }
            );
    }

    private prepareFormData(): any {
        const input = new FormData();
        input.append("file", this.form.get("file").value);
        input.append("model", this.model);

        return input;
    }

    hash(data) {
        const info = {
            id: data.id,
            linking_id: data.linking_id,
        };

        return info;
    }

    favorite(precontact) {
        if (precontact.linking_id === 1) {
            precontact.linking_id = 0;
        } else {
            precontact.linking_id = 1;
        }

        this.update(this.hash(precontact));
    }

    blackList(precontact) {
        if (precontact.linking_id === 2) {
            precontact.linking_id = 0;
        } else {
            precontact.linking_id = 2;
        }

        this.update(this.hash(precontact));
    }

    update(precontact) {
        this.precontactService
            .update(precontact.id, precontact)
            .subscribe((response) => {
                const data = this.dataElements.data;
                const index = data.findIndex((i) => i.id === response.id);
                data[index] = response;
                this.dataElements.data = data;
            });
    }

    profile(element_id) {
        const url = "/precontact/" + element_id;

        window.open(url, "_blank");
    }

    whatsapp(person) {
        this.messageWhatsappDialogRef = this.dialog.open(
            MessageWhatsappDialogComponent,
            {
                data: {
                    person: person ? person : {},
                },
            }
        );

        this.messageWhatsappDialogRef.afterClosed().subscribe((result) => {
            //console.log(result);
        });
    }

    onActivate(precontact) {
        precontact.status_id = precontact.status_id === 1 ? 0 : 1;

        this.precontactService.activate(precontact).subscribe((response) => {
            const data = this.dataElements.data;
            const index = data.findIndex((i) => i.id === response.id);
            data[index] = response;
            this.dataElements.data = data;
        });
    }

    onTransferOtherUser(precontact) {
        this.precontactTransferUserDialogRef = this.dialog.open(
            PrecontactTransferUserDialogComponent,
            {
                data: {
                    user: this.userPoliticies ? this.userPoliticies : {},
                    precontact: precontact ? precontact : {},
                },
            }
        );

        this.precontactTransferUserDialogRef
            .afterClosed()
            .subscribe((result) => {
                // Actualizacón del elemento modificado:
                if (result) {
                    const data = this.dataElements.data;
                    const index = data.findIndex((i) => i.id === result.id);
                    data[index] = result;
                    this.dataElements.data = data;
                }
            });
    }
}
