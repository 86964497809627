// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
/*
export const environment = {
    production: false,
    apiUrl: "http://fourmi-api.test/es/api",
    apiUrlShort: "http://fourmi-api.test/",
    agency_team_id: 23,
    franchise_team_id: 1,
    franchise_sub_team_id: null,
};
*/
export const environment = {
    production: true,
    apiUrl: "https://api-fourmi.flooges.com/es/api",
    apiUrlShort: "https://api-fourmi.flooges.com/",
    agency_team_id: 575,
    franchise_team_id: 1,
    franchise_sub_team_id: null,
};
