import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";
import { environment } from "../../../environments/environment";

import { ApiFourmiService } from "../../shared/api-fourmi.service";

import { UserPoliticiesService } from "../../shared/user-politicies.service";

const agency_team_id = environment.agency_team_id;

@Component({
    selector: "app-select-operation-sale",
    templateUrl: "./select-operation-sale.component.html",
    styleUrls: ["./select-operation-sale.component.scss"],
})
export class SelectOperationSaleComponent implements OnInit {
    @Input() disable?;
    @Input() icon?;
    @Input() operationId;
    @Input() userId;
    @Input() limit;
    @Input() year?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    operations;
    API;
    userPoliticies;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
        private route: ActivatedRoute,
        private router: Router,
        private userPoliticiesService: UserPoliticiesService
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.userPoliticiesInit();
        this.getOperationSale();
    }

    getOperationSale() {
        const params = [];

        if (this.userPoliticies.type_id < 50) {
            params["franchise_id"] = this.userPoliticies.franchise_id;
        }

        if (this.year && this.year !== "") {
            params["year"] = this.year;
        }

        if (this.userId && this.userId !== "") {
            params["user_id"] = this.userId;
        }

        params["agency_id"] = agency_team_id;

        this.autHttp
            .get(`${this.API}/operation-sale`, { params })
            .map((data) => data.json().data)
            .subscribe((response) => (this.operations = response));
    }

    getColor(status_id) {
        let color = "text-center width-normal-100";

        switch (status_id) {
            case 0:
                color = color + " kw-label-red";
                break;
            case 1:
                color = color + " kw-label-blue";
                break;
            case 2:
                color = color + " kw-label-green";
                break;
            default:
                break;
        }

        return color;
    }

    selected(event) {
        this.changed.emit(event.value);
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();

        if (this.userPoliticies.type_id < 30) {
            // No inferiores a Ususario.
            this.router.navigate(["/dashboard"]);
        }
    }
}
