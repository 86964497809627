import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Component({
    selector: 'app-select-agency-user-rank',
    templateUrl: './select-agency-user-rank.component.html',
    styleUrls: ['./select-agency-user-rank.component.scss']
})
export class SelectAgencyUserRankComponent implements OnInit {

    API;

    @Input() rankId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    ranks = [];

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    ngOnInit() {
        this.getRanks();
    }

    getRanks() {
        this.autHttp.get(`${this.API}/translate/crm-type.user.rank`)
            .map(data => data.json()['crm-type.user.rank'])
            .subscribe(response => {
                this.ranks = Object.keys(response).map(function(index) {
                    const obj = {};
                    obj['name'] = response[index];
                    obj['value'] = +index;
                    return obj;
                });
            }
        );
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
