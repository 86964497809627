import { Component, OnInit, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

import { UserPoliticiesService } from '../../shared/user-politicies.service';

@Component({
    selector: 'app-select-user-franchise-subfranchise',
    templateUrl: './select-user-franchise-subfranchise.component.html',
    styleUrls: ['./select-user-franchise-subfranchise.component.scss']
})
export class SelectUserFranchiseSubfranchiseComponent implements OnChanges {

    @Input() disable?;
    @Input() franchiseId?;
    @Input() subfranchiseId?;
    @Input() icon?;
    @Input() userId;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    users;
    API;
    userPoliticies;

    constructor(private autHttp: AuthHttp,
                private apiFourmi: ApiFourmiService,
                private route: ActivatedRoute,
                private router: Router,
                private userPoliticiesService: UserPoliticiesService,
    ) {
        this.API = apiFourmi.get();
        this.userPoliticiesInit();
    }


    ngOnChanges(changes: SimpleChanges) {
        this.getUsers();
    }


    getUsers() {
        const params = [];

        // SuperAdministrador:
        if (this.userPoliticies.type_id === 50) {
            if (this.franchiseId && this.franchiseId > 0) {
                params['franchise_id'] = this.franchiseId;
            }

            if (this.subfranchiseId && this.subfranchiseId > 0) {
                params['franchise_sub_id'] = this.subfranchiseId;
            }
        }

        // Administrador y SubAdministrador (master franquicia provincial):
        if (this.userPoliticies.type_id < 50) {
            params['franchise_id'] = this.userPoliticies.franchise_id;

            // SubAdministrador (master franquicia provincial):
            if (this.userPoliticies.type_id < 40) {
                params['franchise_sub_id'] = this.userPoliticies.franchise_sub_id;
            } else {
                if (this.subfranchiseId && this.subfranchiseId > 0) {
                    params['franchise_sub_id'] = this.subfranchiseId;
                }
            }
        }

        this.autHttp.get(`${this.API}/user`, {params})
            .map(data => data.json().data)
            .subscribe(response => this.users = response);
    }

    selected(event) {
        this.changed.emit(event.value);
    }

    userPoliticiesInit() {
        this.userPoliticies = this.userPoliticiesService.get();

        if (this.userPoliticies.type_id < 30) { // No inferiores a Ususario.
            this.router.navigate(['/dashboard']);
        }
    }
}
