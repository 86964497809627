import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Component({
    selector: 'app-select-franchise',
    templateUrl: './select-franchise.component.html',
    styleUrls: ['./select-franchise.component.scss']
})
export class SelectFranchiseComponent implements OnInit {

    API;

    @Input() disable?;
    @Input() franchiseId;
    @Input() icon?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    franchises;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }


    ngOnInit() {
        this.getFranchises();
    }

    getFranchises() {
        this.autHttp.get(`${this.API}/franchise`)
            .map(data => data.json().data)
            .subscribe(response => this.franchises = response);
    }

    selected(event) {
        this.changed.emit(event.value);
    }
}
