import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import "rxjs/add/operator/map";
import { AuthHttp } from "angular2-jwt";

@Component({
    selector: "app-select-year",
    templateUrl: "./select-year.component.html",
    styleUrls: ["./select-year.component.scss"],
})
export class SelectYearComponent implements OnInit {
    @Input() disable?;
    @Input() icon?;
    @Input() yearId;
    @Input() text?;
    @Input() required?;
    @Output() changed = new EventEmitter<boolean>();

    constructor(
        private autHttp: AuthHttp,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {}

    selected(event) {
        this.changed.emit(event.value);
    }
}
