import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { MeetingService } from '../../../meeting/shared/meeting.service';
import { PrecontactService } from '../../shared/precontact.service';

@Component({
    selector: 'app-precontact-transfer-user-dialog',
    templateUrl: './precontact-transfer-user-dialog.component.html',
    styleUrls: ['./precontact-transfer-user-dialog.component.scss']
})
export class PrecontactTransferUserDialogComponent implements OnInit {

    id;
    meetings = false;
    precontact;
    sendOK = false;
    title = '';
    user;

    constructor(
        private dialogRef: MatDialogRef<PrecontactTransferUserDialogComponent>,
        private meetingService: MeetingService,
        private precontactService: PrecontactService,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) private data
    ) { }

    ngOnInit() {
        this.setData();
    }

    // Setting Comment:
    setData() {
        if (this.data) {
            this.user = this.data.user;
            this.precontact = this.data.precontact;
            this.id = this.data.precontact.id;

            this.title = `ID: ${this.data.precontact.id} - ${this.data.precontact.fullname}`;
        }
    }

    update() {
        this.precontactService.update(this.id, this.precontact).subscribe(response => {
            if (!response.errors) {
                this.sendOK = true;

                setTimeout(() => {
                    if (this.meetings) {
                        this.transfer();
                    }

                    this.closed(response);
                }, 1000);
            }
        });
    }

    transfer() {
        const params = {};

        params['id'] = this.precontact.id;
        params['franchise_id'] = (this.precontact.franchise_id) ? this.precontact.franchise_id : 1;
        params['franchise_sub_id'] = this.precontact.franchise_sub_id;
        params['option'] = 'precontact';
        params['user_assigned_id'] = this.precontact.user_assigned_id;

        this.meetingService.transfer(params).subscribe(response => {
            if (!response.errors) {
                this.sendOK = true;

                setTimeout(() => {
                    this.closed(response);
                }, 1000);
            }
        });
    }

    onChangedUserId(event) {
        this.precontact.user_id = event;
    }

    onChangedUserAssignedId(event) {
        this.precontact.user_assigned_id = event.id;
        this.precontact.franchise_id = (event.franchise_id) ? event.franchise_id : 1;
        this.precontact.franchise_sub_id = (event.franchise_sub_id) ? event.franchise_sub_id : null;
    }

    closed(data) {
        data = data ? data : {};
        this.dialogRef.close(data);
    }
}
