import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { AuthHttp } from 'angular2-jwt';
import { environment } from '../../../environments/environment';

import { ApiFourmiService } from '../../shared/api-fourmi.service';

@Injectable()
export class PrecontactService {
    API;

    constructor(
        private autHttp: AuthHttp,
        private apiFourmi: ApiFourmiService,
    ) {
        this.API = apiFourmi.get();
    }

    activate(precontact) {
        return this.autHttp.post(`${this.API}/precontact-activate`, precontact)
            .map(data => data.json().data);
    }

    list(params) {
        return this.autHttp.get(`${this.API}/precontact`, { params })
        .map(data => data.json());
    }

    get(id: number) {
        return this.autHttp.get(`${this.API}/precontact/${id}`)
            .map(data => data.json().data);
    }

    store(precontact) {
        return this.autHttp.post(`${this.API}/precontact`, precontact)
            .map(data => data.json().data);
    }

    update(id, precontact) {
        return this.autHttp.put(`${this.API}/precontact/${id}`, precontact)
            .map(data => data.json().data);
    }

    delete(id: number) {
        return this.autHttp.delete(`${this.API}/precontact/${id}`)
            .map(data => data.json().data);
    }

    check(params) {
        return this.autHttp.get(`${this.API}/precontact-check`, { params })
            .map(data => data.json().data);
    }
}
